.values-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 88%;
  background-color: #f4f4f4;
  color: #f4f4f4;
  display: flex;
  flex-direction: column; /* Inhalte vertikal ausrichten */
  align-items: center;
  justify-content: top;
  z-index: 1000;
  overflow: hidden;
}

.values-overlay-content {
  width: 90%;
  max-width: 800px;
  max-height: 25%; /* Platz für den Button lassen */
  background-color: #ffffff;
  color: #333;
  border-radius: 4px;
  padding: 75px 35px 15px 15px; /* Nur minimaler Innenabstand */
  overflow-y: auto; /* Scrollbalken aktivieren */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 5px;
}

.values-overlay-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.horizontal-container {
  display: flex;
  flex-direction: row; /* Horizontale Anordnung */
  gap: 20px; /* Abstand zwischen den Charts */
}

.chart-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.chart-section {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h1, h2 {
  text-align: center;
  color: #333;
  margin-top: 0px; /* Reduzierter Abstand nach oben */
  margin-bottom: 10px;
}

.bar-chart {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.bar-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bar-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.bar-label {
  flex: 1;
  text-align: left;
  padding-right: 5px;
  font-size: 0.75rem;
  font-weight: bold;
  color: #333;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bar {
  flex: 4;
  background-color: #a0e0e0;
  height: 12px;
  position: relative;
  border-radius: 2px;
  border: 2px solid #000;
  overflow: hidden;
}

.bar-fill {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
  position: relative;
}

.bar-marker {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  z-index: 2;
}

.bar-marker.min {
  background-color: #ff0000; /* Orange für Minimum */
}

.bar-marker.default {
  background-color: white; /* Grün für Default */
}

.bar-marker.max {
  background-color: #000000; /* Blau für Maximum */
}

.bar-value {
  flex: 1;
  text-align: left;
  font-size: 0.75rem;
  color: #333;
}

.close-overlay-button {
  position: fixed; /* Button bleibt immer sichtbar */
  bottom: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  z-index: 1100; /* Überlappt alles andere */
}

.close-overlay-button:hover {
  background-color: #0056b3;
}

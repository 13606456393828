.avatar-selection-container {
  text-align: center;
  padding: 20px;
}

.avatar-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  color: black;
}

.avatar-grid h1{
	color: black;
}

.avatar-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.avatar-card img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.avatar-card h3 {
  margin: 10px 0 5px;
  font-size: 18px;
}

.avatar-card p {
  margin: 0 0 10px;
  font-size: 14px;
  color: #555;
}

.avatar-card button {
  margin: 5px;
  padding: 8px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.avatar-card button:first-of-type {
  background-color: #007bff;
  color: white;
}

.avatar-card button:first-of-type:hover {
  background-color: #0056b3;
}

.avatar-card button:last-of-type {
  background-color: #f5f5f5;
  color: #333;
}

.modal-content {
	color: black;
}

.avatar-card button:last-of-type:hover {
  background-color: #ddd;
}

.avatar-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.avatar-info-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.avatar-info h2, .avatar-info h3, .avatar-info p {
  text-align: center;
  margin: 10px 0;
}

.close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #0056b3;
}

.progress-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    transition: height 0.3s ease;
}

.progress-bar-container.hidden {
    height: 0;
    overflow: hidden;
    opacity: 0;
}

.progress-bar-container.visible {
    height: 4px;
    opacity: 1;
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #f3f3f3;
    border-radius: 0px;
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    width: 0;
    transition: width 0.5s linear;
}



.chat-output {
    text-decoration: none; /* Entfernt jegliche Unterstreichung */
    text-align: left !important;
    align-items: flex-start; 
    background: white;
    margin: 0px;
}

.chat-output p {
    text-align: left !important;
    font-size: 14px;
}



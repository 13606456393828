/* Stack-Container */

.app-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Nimmt die gesamte Höhe des Viewports ein */
  margin: 0;
  background-color: #aabbff;
  box-sizing: border-box;
  overflow: hidden;
}


.stack-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 110px);
  margin: 0;
  background-color: #aabbff;
  box-sizing: border-box;
  overflow: hidden;

}

/* Chat-Container */
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #aabbff;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  border: none;
}

/* Chat-Ausgabe */
.chat-output {
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 5px 10px;
  background-color: #fff;
  overflow-y: auto;
  border: 2px solid #ccc;
  box-sizing: border-box;
  text-align: left;  
}

.chat-output p {
  margin: 0;
  font-size: 18px;
  color: #333;
  text-align: left;  
}

/* Avatar im Eingabebereich */
.avatar-in-input {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border: 2px solid #ccc;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

/* Avatar-Bild */
.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/* Symbole im Eingabebereich */
.icon-button {
  font-size: 24px;
  color: #555;
  background: none;
  border: none;
  cursor: pointer;
}

.icon-button:hover {
  color: #007bff;
}

.send-button {
  color: #007bff;
}

/* Login-Seite */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  text-align: center;
}

.login-container h1 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #333;
}

.login-container p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #666;
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Stil für Benutzernachrichten */
.user-message {
  text-align: left;
  padding-left: 10px;
  color: #333;
}

/* Stil für KI-Nachrichten */
.bot-message {
  text-align: left;
  padding-left: 30px;
  color: #007bff;
}

/* Avatar-Auswahlseite */
.avatar-selection-container {
  padding: 20px;
  text-align: center;
}

.avatar-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.avatar-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.avatar-card img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.avatar-card h3 {
  margin: 10px 0 5px;
}

.avatar-card p {
  margin: 5px 0;
  font-size: 14px;
}

.avatar-card button {
  margin: 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 14px;
}

.avatar-card button:hover {
  background-color: #0056b3;
}

/* Modal für Avatar-Info */
.info-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.info-modal button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.info-modal button:hover {
  background-color: #0056b3;
}

.language-flag {
  font-size: 24px;
  margin-left: 10px;
  align-self: center;
}

.pricing-page-container {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.pricing-toggle {
  margin: 20px 0;
}

.toggle-button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f7f7f7;
  cursor: pointer;
}

.toggle-button.active {
  background: #000;
  color: #fff;
}

.pricing-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

/* Jede Pricing-Karte hat eine feste Breite und nutzt Flexbox */
.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Sorgt dafür, dass der Button unten bleibt */
  width: 280px;
  min-height: 400px; /* Mindeshöhe für gleiche Karten */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

}

.pricing-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.pricing-card .price {
  font-size: 20px;
  color: #007bff;
  margin-bottom: 10px;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.pricing-card li {
  text-align: left;
  margin: 5px 0;
  padding-left: 25px; /* Abstand für Haken */
  position: relative;
  text-indent: -20px; /* Zeilenrückzug */
  word-break: break-word; /* Zeilenumbruch, falls nötig */
  overflow-wrap: break-word; /* Falls `word-break` nicht funktioniert */
    margin-left: 10px;
}

.pricing-card li span.green-check {
  position: absolute;
  left: 0;
  top: 0;
  color: green;
  font-weight: bold;
}

.pricing-card .select-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pricing-card .select-button.disabled {
  background: #ccc;
  cursor: not-allowed;
}

.pricing-card.pro {
  background: #f0f8ff;
  border-color: #007bff;
}

.green-check {
  color: green;
  font-weight: bold;
}

/* Wrapper für den Button */
.button-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Unsichtbare Fläche, um Klicks auf deaktivierte Buttons zu registrieren */
.disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; /* Normaler Cursor, damit kein roter Kreis erscheint */
  background: transparent;
}

/* Dieser Container sorgt dafür, dass der Button am unteren Rand bleibt */
.button-container {
  margin-top: auto; /* Drückt den Button nach unten */
  padding-top: 15px;
}

/* Button ist standardmäßig grün */
.select-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: green;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.select-button:hover {
  background-color: darkgreen;
}

/* Deaktivierte Buttons */
.select-button.disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Popup-Meldung für "Noch nicht verfügbar" */
.alert-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  animation: fadeInOut 3s ease-in-out;
}

/* Ein-/Ausblende-Effekt */
@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

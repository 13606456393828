
.home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #0056b3;
}


.login-container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
      background: linear-gradient(45deg, #fff, #eee, #ddd, #ccc);
    background-size: 300% 300%;
    animation: gradientAnimation 30s infinite linear alternate;
    /* border: 1px solid red; */
}

/* Farbverlauf animieren */
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.language-selection {
  margin-bottom: 20px;
}

.flags-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.flag-icon {
  width: 40px;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;
}

.flag-icon:hover {
  transform: scale(1.1);
}

.flag-icon.selected {
  border: 2px solid #007bff;
  border-radius: 4px;
}

.login-buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Abstand zwischen den Buttons */
  margin-top: 20px;
}


.login-submit-button {
  margin-top: 10px;
}



.try-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
}

.try-button:hover {
  background-color: #218838;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.error-popup {
  position: absolute;
  top: -30px;
  right: 10px;
  background: white;
  color: red;
  border: 1px solid red;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 10;
}


.input-error {
  border: 2px solid red;
}

.error-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: red;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.popup-content {
  margin: 10px 0;
}

.popup-content button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.verification-button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.facebook-login {
  position: absolute; /* Positionierung relativ zum Container */
  top: 20px; /* Abstand vom oberen Rand */

  background-color: #1877f2;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.facebook-login:hover {
  background-color: #145dbf;
}

.social-media-button {
  height:60px;
  top-margin:10px;
  cursor: pointer;
}
.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 10px;
  padding: 0;
}

.icon-image {
  width: 50px;
  height: 50px;
}

/* ----------------------------------------------------------------------------- */

.manual-login-form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Abstand zwischen Eingabefeldern */
  width: 280px; /* Breite des Containers */
  height: 230px;
  margin: 0 auto; /* Zentriert den Container */
  border: 2px solid #888;
  position: relative; /* Bezugspunkt für absolute Positionierung */
  padding: 20px; /* Innenabstand für die Eingabefelder */
}

.close-button-2 {
  position: absolute; /* Positionierung relativ zum Container */
  top: 0px; /* Abstand vom oberen Rand */
  right: 0px; /* Abstand vom rechten Rand */
  font-size: 16px;
  width: 25px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: none;
  align-items: center; /* Zentrierung des Symbols */
  background-color: transparent;
}

.close-button-2:hover {
  background-color: #ff8888; /* Hellere rote Farbe beim Hover */
}

.input-field {
  position: relativ; /* Positionierung relativ zum Container */
  display: flex;
  flex-direction: column;
}

.input-field-name {
  position: absolute; /* Positionierung relativ zum Container */
  top: 30px; /* Abstand vom oberen Rand */
  display: flex;
  right: 10px;
  left:10px;
  flex-direction: column;
}

.input-field-email {
  position: absolute; /* Positionierung relativ zum Container */
  top: 60px; /* Abstand vom oberen Rand */
  display: flex;
  right: 10px;
  left:10px;
  flex-direction: column;
}

.input-field-password {
	  position: absolute; /* Positionierung relativ zum Container */
  top: 100px; /* Abstand vom oberen Rand */
  right: 10px;
  left:10px;
  display: flex;
  flex-direction: column;
}

.input-field-password-confirmation {
	  position: absolute; /* Positionierung relativ zum Container */
  top: 130px; /* Abstand vom oberen Rand */
  right: 10px;
  left:10px;
  display: flex;
  flex-direction: column;
}

.button-group {
  position: absolute; /* Positionierung relativ zum Container */
  top: 160px; /* Abstand vom oberen Rand */
  right: 10px;
  left:10px;

  display: flex;
  justify-content: space-between; /* Buttons voneinander trennen */
  gap: 0px; /* Abstand zwischen den Buttons */
  margin-top: 10px; /* Zusätzlicher Abstand nach oben */
}

.register-button {
  width: 150px; /* Einheitliche Breite für beide Buttons */
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4285f4;
  color: white;
  margin-top: 0px;
  margin-right: 0px; /* Zwischenraum rechts neben dem Button */
  height: 90px;
}

.register-button:hover {
  background-color: #0056b3;
}

.login-button-1 {
  width: 150px; /* Einheitliche Breite für den Button */

  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4285f4;
  color: white;
  margin-top: 0px;
  margin-left: 0px; /* Zwischenraum rechts neben dem Button */
  margin-right: 10px; /* Zwischenraum rechts neben dem Button */
  height: 60px;
}

.login-button-1:hover {
  background-color: #0056b3;
}

.login-button {
  width: 150px; /* Einheitliche Breite für den Button */

  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4285f4;
  color: white;
  margin-top: 0px;
  margin-left: 0px; /* Zwischenraum rechts neben dem Button */
  margin-right: 10px; /* Zwischenraum rechts neben dem Button */
  height: 90px;
}

/* ----------------------------------------------------------------------- */

.login-buttons-container {
  display: flex;
  flex-direction: row; /* Icons und Button in einer Zeile */
  justify-content: center; /* Zentriert den gesamten Inhalt */
  align-items: center; /* Vertikale Zentrierung */
  gap: 20px; /* Abstand zwischen den Gruppen (Icons und Button) */
  height: 150px; /* Höhe des Containers */
}

.login-icons-row {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Icons horizontal zentrieren */
  align-items: center; /* Vertikale Zentrierung */
  gap: 10px; /* Abstand zwischen den Icons */
}

.back-to-login-button {
  width: 140px; /* Einheitliche Breite für beide Buttons */
    height: 90px;

  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4285f4;
  color: white;
}

.back-to-login-button:hover {
  background-color: #0056b3;
}


/* ----------------------------------------------------------------------- */

.login-button:hover {
  background-color: #0056b3;
}
.login-manual-row {
  margin-top: 10px;
}

/* ----------------------------------------------------------------------- */

.login-try-row {
  margin-top: 10px;
}

.manual-login-button {
  background-color: #f1f1f1;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.manual-login-button:hover {
  background-color: #e0e0e0;
}

.try-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.try-button:hover {
  background-color: #218838;
}

.trial-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.trial-form h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.trial-submit-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.trial-submit-button:hover {
  background-color: #218838;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}




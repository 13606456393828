.language-selection {
  text-align: center;
  margin-bottom: 20px;
}

.flags-container {
  display: flex;
  flex-wrap: wrap; /* Ermöglicht das Umbruchverhalten */
  justify-content: center;
  gap: 10px; /* Abstand zwischen den Flaggen */
  margin: 10px 0;
  max-width: 100px; /* Begrenzung der Breite, um die Flags auf zwei Zeilen zu verteilen */
  margin-left: auto;
  margin-right: auto;
}

.flag-icon {
  width: 20px;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;
}

.flag-icon:hover {
  transform: scale(1.1);
}

.flag-icon.selected {
  border: 4px solid #000077;
  border-radius: 1px;
}

.voice-mic-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  padding: 5px;
  transition: color 0.2s;
}

.icon-button.active {
  color: #007bff; /* Farbe für aktiven Zustand */
}

.icon-button:hover {
  color: #0056b3; /* Hover-Farbe */
}

/* Eingabebereich */
.chat-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 0px;
  border: none;
  box-sizing: border-box;
  min-height: 120px;
}

/* ========== CONTAINER ==================================================== */

.vertical-container {
  display        : flex;
  flex-direction : column; /* Symbole übereinander anordnen */
  gap            : 2px; /* Platz zwischen den Buttons */
  align-items    : top; /* Zentrierung */
  /*border         : 1px solid #f00;*/
}

.vertical-container-tokens {
  display        : flex;
  flex-direction : column; /* Symbole übereinander anordnen */
  gap            : 2px; /* Platz zwischen den Buttons */
  align-items    : top; /* Zentrierung */
  /*border         : 1px solid #f00;*/
  /* height		 : 60px; */
}

.vertical-container-tokens p {
	gap            : 2px;
	/*border: 1px solid #f00 */;
	margin: 2;
    padding: 0;
    line-height: 1.4; 
    font-size: 11px;
}

.avatar-container {
  display: flex;
  flex-direction: column; /* Symbole übereinander anordnen */
  gap: 2px; /* Platz zwischen den Buttons */
  align-items: top; /* Zentrierung */
  /*border: 2px solid #f00;*/
}

.button-container2 {  
  display: flex;
  justify-content: flex-start; /* Elemente nach links ausrichten */
  align-items: top;
  gap: 1px;
  padding: 0px; /* Innenabstand für besseren Look */
  width: auto;
  /*border: 2px solid #aaa;*/
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.vertical-container.align-start {
  align-items: flex-start; /* Links ausrichten */
}

/* ========== BUTTONS ====================================================== */

.voice-button {
  background: none;
  border: 2px solid;
  border-radius: 3px;
  cursor: pointer;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mic-button {
  background: none;
  border: 2px solid;
  border-radius: 3px;
  cursor: pointer;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voice-button.active {
  color: #007bff; /* Aktivierter Zustand */
}

.mic-button.active {
  color: #007bff; /* Aktivierter Zustand */
}


.language-flag:hover {	
  margin-left: auto;
  border: 2px solid #ccf;
  background: none;
  cursor: pointer;
}

.emotion-button {
  background-color  : #f0f0f0;
  border            : 1px solid #ccc;
  border-radius     : 5px;
  padding           : 5px 10px;
  cursor            : pointer;
  width             :30px;
}

.language-button2 {
  display: flex;
  align-items: left;
  justify-content: center; /*flex-start; */ /* Inhalte nach links ausrichten */
  margin-bottom: 1px; /* Abstand zwischen den Buttons */
  font-size: 18px;
  width: 32px;
  height: 24px;
}

.show-image-button {
  display: flex;
  align-items: left;
  justify-content: center; /* Inhalte nach links ausrichten */
  margin-bottom: 5px; /* Abstand zwischen den Buttons */
    height: 24px;
}

.image-button:hover {
  background: darkred;
}

.end-session-button {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
}

.end-session-button:hover {
  background: lightblue;
}

.icon-button-end-button {
  margin-left: auto; /* Schiebt den Button nach rechts */
  background: none; 
  border: none;
  padding: 5px;
  cursor: pointer;
}

.icon-button-end-button:hover {
  background: lightblue;
  color: white;
}

.close-button {
  background: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  align-self: right;
}

.close-button:hover {
  background: #ff1a1a;
}

.close-popup-button {
  margin-top: 10px;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.emotions-button {
  width: 45px; /* Einheitliche Breite für den Button */
  border: none;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  background-color: #4285f4;
  color: white;
  margin-top: 0px;
  margin-left: 0px; /* Zwischenraum rechts neben dem Button */
  margin-right: 10px; /* Zwischenraum rechts neben dem Button */
  height: 25px;

}

.emotions-model-button {
  width: 45px; /* Einheitliche Breite für den Button */
  border: none;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  background-color: #4285f4;
  color: white;
  margin-top: 0px;
  margin-left: 0px; /* Zwischenraum rechts neben dem Button */
  margin-right: 10px; /* Zwischenraum rechts neben dem Button */
  height: 25px;

}

.emotions-model-button:hover {
  background-color: #0056b3;
}

.version-button {
  margin-top: 1px;
  font-size: 10px;
  color: #333;
  transition: background-color 0.2s ease;
  width: 45px;
  height: 23px;
}

.version-button:hover {
  background-color: #e0e0e0;
}

/* ========== Mehrzeiliges Eingabefeld ====================================*/

.input-field {
  flex: 1;
  width: 100%;

  font-size: 16px;
  
  outline: none;
  background-color: #f2f2f2;
  resize: none;
  overflow-y: auto;
  line-height: 1.5;
  box-sizing: border-box;
  max-height: calc(1.5em * 3);

  margin-right: 3px;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 1px; /* Abgerundete Ecken für besseren Look */
}

.input-field:focus {
  background-color: #ffffff;
}



/* Eingabebereich */
.chat-input-buttons {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 0px;
  border: none;
  box-sizing: border-box;
  min-height: 70px;
  border: 2px solid;
}



.avatar-not-pulsing {
  padding: 2px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.modal-avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 10px 0;
}


@keyframes pulse {
  0%, 100% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(0.8);
  }
}

.avatar-pulsing {
  animation: pulse 1.5s infinite;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.language-selector-popup {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}







.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-container {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.button-container {
	height: 200 px;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 15px;
    /* border: 2px solid #f00;*/
}

.close-overlay-button {
  position: absolute;
  top: 20px;
  right: 30px;
  background: #ff5c5c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 100px;
  height: 40px;
  cursor: pointer;
  font-size: 16px;
}

.close-overlay-button:hover {
    background-color: #0056b3;
}

.download-image-button {
  position: absolute;
  top: 100 px;
  right: 00px;
  background: #ff5c5c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 100px;
  height: 40px;
  cursor: pointer;
  font-size: 16px;
}

.download-image-button:hover {
    background-color: #0056b3;
}


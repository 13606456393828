.avatar-container {
  display: inline-block;
  cursor: pointer;
}

.avatar-image {
  width: 50px;
  height: 50px;
  border-radius: 10%;
  object-fit: cover;
}

.avatar-talking-head {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.avatar-image-speaking {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  animation: talking 0.5s infinite;
}

@keyframes talking {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/*
.avatar-container {
  text-align: center;
}


.avatar-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

*/



.modal-content {
  text-align: left;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.modal-content p {
	text-align: left;
  margin: 10px 0;
  font-size: 1em;
  line-height: 1.5;
  
  text-indent: 0; /* Verhindert Einzug für die erste Zeile */
  /*padding-left: 150px;*/ /* Einrückung für Folgezeilen */
  
  /*text-indent: 150px;*/ /* Für die erste Zeile */
}

.modal-content p.biography {
  text-align: left;
  margin: 10px 0;
  font-size: 1em;
  line-height: 1.5;
  padding-left: 150px; /* Einrückung für Folgezeilen */
  text-indent: -150px; /* Verschiebt die erste Zeile */
}

.modal-content p.biography strong {
  display: inline-block;
  width: 150px; /* Breite, die exakt mit padding-left übereinstimmt */
  text-indent: 0px;
}

.modal-content p.hobbies {
	text-align: left;
  
  padding-left: 150px; /* Einrückung für Folgezeilen */
  text-indent: -150px; /* Verschiebt die erste Zeile */
}

.modal-content p strong {
	text-align: left;
  display: inline-block;
  width: 150px; /* Optionale Breite für gleichmäßige Ausrichtung */
}

.close-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #f44336;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #d32f2f;
}
